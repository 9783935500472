import React from 'react';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ReactMarkdown from 'react-markdown';

import Layout from '../../components/layout';
import HeadHelmet from '../../components/head';
import styled from 'styled-components';
import { StyledBackground } from '../../components/ui-elements/backgroundImage';
import { GetQuoteDiv } from '../../components/pages/solutions/getQuoteDiv';
import Portfolio from '../../components/Portfolio';

export const Section = styled.section`
  max-width: 1440px;
  margin: 2rem auto;
  .section-wrapper {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin:0 auto;
    @media (min-width: 960px) {
      flex-direction: row;
      justify-content: space-around;
    }
  }
  .content-wrapper {
    order: 1;
    @media (min-width: 960px) {
      order: 1;
      width: 45%;
    }
    ul {
      font-family: Lato, SansSerif;
      font-size: 20px;
    }
    li::marker {
      color: var(--bullet-point-color);
    }
  }
  .image-wrapper {
    order: 2;
    @media (min-width: 960px) {
      order: 1;
      width: 45%;
    }
  }
`;

const ArchitecturalDesign = ({ data, location }) => {
  const { strapiSsArchitectural } = data;
  const {
    metaData,
    hero_image,
    title,
    sectionUnderHero,
    bottomGetQuoteContent,
    portfolioSLUG,
    section1Title,
    section1Content,
    section1Image,
    section2Title,
    section2Content,
    section2Image,
    section3Title,
    section3Content,
    section3Image,
  } = strapiSsArchitectural || {};
  return (
    <>
      <HeadHelmet data={ metaData } />
      <Layout>
        <StyledBackground
          img={ hero_image?.childImageSharp?.fluid }
          title={ title }
          location={ location?.pathname }
          titleWidth="100%"
        />
        <GetQuoteDiv>
          <ReactMarkdown>{ sectionUnderHero }</ReactMarkdown>
        </GetQuoteDiv>
        <Section>
          <div className={'section-wrapper'}>
            <div className={'content-wrapper'}>
              <h3>{section1Title}</h3>
              <ReactMarkdown>{section1Content}</ReactMarkdown>

            </div>
            <div className={'image-wrapper'}>
              <Img alt={''} fluid={section1Image.childImageSharp?.fluid} />
            </div>
          </div>
        </Section>
        <Section>
          <div className={'section-wrapper'}>
            <div className={'image-wrapper'}>
              <Img alt={''} fluid={section2Image.childImageSharp?.fluid} />
            </div>
            <div className={'content-wrapper'}>
              <h3>{section2Title}</h3>
              <ReactMarkdown>{section2Content}</ReactMarkdown>
            </div>
          </div>
        </Section>
        <Section>
          <div className={'section-wrapper'}>
            <div className={'content-wrapper'}>
              <h3>{section3Title}</h3>
              <ReactMarkdown>{section3Content}</ReactMarkdown>
            </div>
            <div className={'image-wrapper'}>
              <Img alt={''} fluid={section3Image.childImageSharp?.fluid} />
            </div>
          </div>
        </Section>
        <GetQuoteDiv
          fontStyle={'normal'}
          fontFamily={'Lato, SansSerif'}
          fontWeight={'700'}
          fontSize={'24px'}
        >
          <ReactMarkdown>{ bottomGetQuoteContent }</ReactMarkdown>
        </GetQuoteDiv>
        <Portfolio href={ portfolioSLUG } />
      </Layout>
    </>
  )
};

export default ArchitecturalDesign;

export const pageQuery = graphql`
  query {
  strapiSsArchitectural {
    metaData {
      metaDescription
      metaKeywords
      title
    }
    title
    hero_image {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    sectionUnderHero
    section1Title
    section1Content
    section1Image {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section2Title
    section2Content
    section2Image {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    section3Title
    section3Content
    section3Image {
      childImageSharp {
        fluid(maxWidth: 1920, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    bottomGetQuoteContent
    portfolioSLUG
  }
}
`;

