import React from "react"
import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: var(--theme-background-gradient);
  opacity: 1;
  color: #FFFFFF;
  padding: 1rem;
  margin-bottom: 0.3rem;

  @media (min-width: 960px) {
    flex-direction: row;
    justify-content: center;
  }

  .content-wrapper {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    @media (min-width: 960px) {
      max-width: 50%;
      @media (min-width: 960px) {
        margin-right: 1.5rem;
        margin-left: 2.5rem;
      }
    }
    .title {
      text-align: center;
      font-size: 21px;
      margin: 1.5rem 0 1rem 0;
      letter-spacing: 0px;
      @media (min-width: 960px) {
        text-align: start;
      }
      @media (min-width: 1200px) {
        font-size: 28px;
      }
    }
    .content {
      text-align: center;
      font-weight: bold;
      font-size: 24px;
      line-height: 1.5;
      @media (min-width: 960px) {
        text-align: start;
      }
      @media (min-width: 1200px) {
        font-size: 34px;
      }
    }
  }
  .button-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    a {
      text-decoration: none;
      margin: 0.5rem 0 1.5rem 0;
      font-family: Lato, sans-serif;
      font-size: 16px;
      color: #FFFFFF;
      padding: 0.5rem 1rem;
      box-shadow: 0px 2px 10px #002F6C33;
      background-color: transparent;
      border: 2px solid #FFFFFF;
      border-radius: 22px;
      @media (min-width: 960px) {
        margin-top: -1rem;
      }
    }
    a:hover,
    a:active {
      color: #002F6C;
      background-color: #FFFFFF;
      cursor: pointer;
    }
  }
`;

const Portfolio = ({href}) => {
  return (
    <Wrapper>
    <div className={'content-wrapper'}>
      <div className={'title'}>
        Portfolio
      </div>
      <div className={'content'}>
        We’ve designed some pretty cool restaurant kitchens. Take a look at some of our other past commercial kitchen design projects.
      </div>
    </div>
    <div className={'button-wrapper'}>
      <a href={href || '#'}>View Our Project Gallery</a>
    </div>
  </Wrapper>
  );
};

export default Portfolio;
